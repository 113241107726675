<template>
  <div id="app">
    <HeaderPart />
    <div class="socialMediaBox" v-if="screenWidth > screenBreakLG">
      <a href="https://www.facebook.com/Michael.Bully.Herbig/" target="_blank"
        ><img src="@/assets/icons/facebook.svg" alt="Facebook Icon"
      /></a>
      <a href="https://www.instagram.com/bullyherbig/" target="_blank"
        ><img src="@/assets/icons/instagram.svg" alt="Instagram Icon"
      /></a>
      <a href="https://www.youtube.com/@michaelbullyherbig" target="_blank"
        ><img src="@/assets/icons/youtube.svg" alt="Youtube Icon"
      /></a>
    </div>
    <main role="main">
      <transition name="fade" mode="in-out">
        <router-view :key="$route.fullPath" />
      </transition>
    </main>
    <FooterPart />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useWindowScroll } from "@vueuse/core";
export default {
  name: "App",
  metaInfo() {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const currentUrl = `${baseUrl}${this.$route.fullPath}`;
    const imageUrl = baseUrl + require("@/assets/images/bully-herbig_462_final.jpg") 

    return {
      title: `Michael Bully Herbig - ${this.capitalizedLinkText}`, // Titel mit dynamischer Ergänzung
      meta: [
        // SEO Meta-Tags
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "Offizielle Website von Michael Bully Herbig, Regisseur, Schauspieler, Autor und Produzent.",
        },
        { name: "robots", content: "index, follow" }, // SEO: Anweisung an Suchmaschinen
        { name: "author", content: "Michael Bully Herbig" }, // SEO: Seitenautor

        // Open Graph Meta-Tags (Social Media)
        {
          property: "og:title",
          content: `Michael Bully Herbig - ${this.capitalizedLinkText}`,
        }, // Open Graph Titel
        {
          property: "og:description",
          content:
            "Offizielle Website von Michael Bully Herbig, Regisseur, Schauspieler, Autor und Produzent.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: currentUrl }, // Open Graph URL
        { property: "og:image", content: imageUrl }, // Open Graph Bild
        { property: "og:site_name", content: "Michael Bully Herbig" },
        { property: "og:locale", content: "de" }, // Sprache für Open Graph

        // Twitter Cards Meta-Tags
        { name: "twitter:card", content: "summary_large_image" }, // Twitter Card Type
        {
          name: "twitter:title",
          content: `Michael Bully Herbig - ${this.capitalizedLinkText}`,
        }, // Twitter Titel
        {
          name: "twitter:description",
          content:
            "Offizielle Website von Michael Bully Herbig, Regisseur, Schauspieler, Autor und Produzent.",
        },
        { name: "twitter:image", content: imageUrl }, // Twitter Bild
      ],
      link: [
        {
          rel: "canonical",
          href: currentUrl, // Setzt die Canonical-URL mit der Basis-URL und dem aktuellen Pfad
        },
      ],
      htmlAttrs: {
        lang: this.curLang || "de", // Sprache der Seite
      },
    };
  },
  computed: {
    ...mapState([
      "cmsUrl",
      "screenWidth",
      "screenBreakLG",
      "activeMenuPoint",
      "navOpen",
      "token",
      "pages",
      "curLang",
    ]),
    capitalizedLinkText() {
      const linkText = this.$route.path.replace("/", "") || ""; // Entfernt den führenden Slash
      return linkText.charAt(0).toUpperCase() + linkText.slice(1); // Erster Buchstabe groß
    },
    page: {
      get() {
        return (
          this.pages.find(
            (page) => page.attributes.pagename === this.$route.name
          )?.attributes || {}
        );
      },
      deep: true,
    },
  },
  components: {
    HeaderPart: () => import("@/components/templateParts/HeaderPart.vue"),
    FooterPart: () => import("@/components/templateParts/FooterPart.vue"),
  },

  methods: {
    handleResize() {
      this.$store.dispatch("setScreenWidth");
    },
    async fetchInitalData() {
      // Awards
      let stateName = "awards";
      let apiUrl = `${process.env.VUE_APP_API_URL}/api/mbh-${stateName}?populate=*&sort[0]=year:desc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // Biographies
      stateName = "biographies";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/mbh-${stateName}?populate=*&sort[0]=title:desc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // Filmographies
      stateName = "filmographies";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/mbh-${stateName}?populate=*&sort[0]=year:asc`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // header Images
      stateName = "headerimg";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate[MBH_AuszeichnungenHeaderImage][populate]=*&populate[MBH_FilmografieHeaderImages][populate]=*&populate[MBH_homeSlider][populate]=*&populate[MBH_BiografieSlider][populate]=*&populate[MBH_BiografieFooterImage][populate]=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // privacy
      stateName = "privacy";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });
    },
  },
  created() {
    this.fetchInitalData();
    this.$store.dispatch("initializeLanguage");
  },
  mounted() {
    // Set ScreenWidth
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // Update ScrollPosition
    const { y } = useWindowScroll();
    const handleScroll = () => {
      this.$store.commit("SET_SCROLL_POSITION", y.value);
    };
    window.addEventListener("scroll", handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'
.socialMediaBox
  position: fixed
  top: 30vh
  left: 0
  width: auto
  height: auto

  z-index: 5
  transition: transform .5s
  a
    display: block
    height: 3rem
    width: 3rem
    margin: 0
    background-color: $gold
    img
      width: 45%
      margin: 25% 25%
    &:hover
      transform: scale(1.1)

  &.mobile
    bottom: 15vh
    right: 0 !important
    left: auto
    top: auto
</style>
