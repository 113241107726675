import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";
import apiClient from "@/services/apiClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cmsUrl: process.env.VUE_APP_API_URL,
    token: process.env.VUE_APP_TOKEN,
    screenWidth: null,
    screenBreakXL: 1200,
    screenBreakLG: 992,
    activeMenuPoint: null,
    scrollPosition: 0,
    navOpen: false,
    curLang: "de",
    showLangBox: true,
    // data from cms
    awards: [],
    biographies: [],
    filmographies: [],
    headerimg: {},
    privacy: {},
    fallbackImage: require("@/assets/images/blackDummy.png"),
  },
  getters: {},
  mutations: {
    SET_DATA(state, { data, stateName }) {
      state[stateName] = data.data;
    },
    updateScreenWidth(state, width) {
      state.screenWidth = width;
    },
    updateActiveMenuPoint(state, ID) {
      state.activeMenuPoint = ID;
    },
    updateNavOpen(state, status) {
      state.navOpen = status;
    },
    setCurLang(state, lang) {
      state.curLang = lang;
      i18n.locale = lang;
      localStorage.setItem("locale", lang);
    },
    SET_SCROLL_POSITION(state, position) {
      state.scrollPosition = position;
    },
  },
  actions: {
    setScreenWidth({ commit }) {
      let width = window.innerWidth;
      commit("updateScreenWidth", width);
    },
    async fetchDataFromApi({ commit }, { apiUrl, stateName }) {
      try {
        const response = await apiClient.get(apiUrl);
        commit("SET_DATA", { data: response.data, stateName });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    initializeLanguage({ commit }) {
      const browserLang = navigator.language.split("-")[0];
      const storedLang = localStorage.getItem("locale");
      const defaultLang =
        browserLang === "de" || browserLang === "en" ? browserLang : "de";

      const langToSet = storedLang || defaultLang;
      commit("setCurLang", langToSet);

      if (!storedLang) {
        localStorage.setItem("locale", langToSet);
      }
    },
  },
  modules: {},
});
