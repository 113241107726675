import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import i18n from "./i18n";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// VUE META
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  keyName: "metaInfo", // Der Standardname, den vue-meta verwendet
  refreshOnceOnNavigation: true, // Aktualisiert Meta-Tags nur einmal pro Navigation
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
