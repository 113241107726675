import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
    alias: "/kontakt",
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("../views/ImprintView.vue"),
    alias: "/impressum",
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
    alias: "/datenschutz",
  },
  {
    path: "/biography",
    name: "biography",
    component: () => import("../views/BiographyView.vue"),
    alias: "/biografie",
  },
  {
    path: "/productions",
    name: "filmography",
    component: () => import("../views/FilmographyView.vue"),
    alias: "/produktionen",
  },
  {
    path: "/awards",
    name: "awards",
    component: () => import("../views/AwardsView.vue"),
    alias: ["/auszeichnungen"],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    // Wenn ein Anchor-Link vorhanden ist, zum Element scrollen
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    // Standardmäßig zur oberen Position der Seite scrollen
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Close MobileNav
  store.commit("updateNavOpen", false);
  next();
});

export default router;
